import React, { useEffect, useState, useRef } from 'react';
import { Link, useLocation, useParams} from "react-router-dom";
import '../../../assets/css/dashboard.css';
import '../../../assets/css/style.css';
import '../../../assets/css/sds.css';
import sdsbannar from '../../../assets/img/sds-banner.jpg';
import { ApiHelper } from '../../../helpers/ApiHelper';
import { API } from '../../../config/api';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Helmet } from "react-helmet";
import HelmetWrapper from '../../../components/CommonHelmet';
import SriDarbarSahib from './SDS';


function SriDarbarSahibHukumDate() {
    const location = useLocation();
    const { h_date } = useParams();
    const [isNos, setIsNos] = useState(false);
    const hasReloaded = useRef(false); 
    useEffect(() => {
        const reloadedPath = sessionStorage.getItem("reloadedPath");
    
        if (reloadedPath !== location.pathname) {
          sessionStorage.setItem("reloadedPath", location.pathname);
          window.location.reload();
        }
      }, [location.pathname]);
    
    useEffect(() => {
        console.log('NO', h_date);
        if (h_date !== '') {
            setIsNos(true)
        }

    }, [h_date])

    return (
        <div>
            {isNos ?
            <SriDarbarSahib  hukum_date={h_date} /> : null}
        </div>
    )
}

export default SriDarbarSahibHukumDate