import './App.css';
import "./index.css";
import Routing from "./config/routes";
import { PageProvider } from './components/PageContext';
function App() {
  return (
    <div>
      <PageProvider>
      <Routing />
      </PageProvider>
      
    </div>
  );
}
export default App;